import { useRouter } from "next/router"
import React from "react"
import { t } from "translations"

import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"
import shouldAbTestPaging from "lib/pagingAbTesting"

import Icon from "Components/Icon/Icon"
import { IPagingContext } from "Components/Layout/PageComponent/PageDataProvider"
import Link from "Components/Link"

export default function ToC({
    page,
    paging
}: {
    page: IPublic
    paging: IPagingContext | null
}) {
    const router = useRouter()
    if (!paging?.pages?.length) return null

    const abTestPaging = shouldAbTestPaging(page.url)

    return (
        <StyledToC>
            <Typography
                variant="body1"
                fontWeight={700}>
                {t.article.tocTitle}
            </Typography>
            <StyledListWrapper>
                <StyledList>
                    {paging?.pages.map((section, index) => (
                        <StyledListItem
                            key={section.url}
                            color="inherit">
                            {abTestPaging ? (
                                <StyledAbLink
                                    variant="body2"
                                    iscurrent={getIsCurrent(
                                        abTestPaging,
                                        section,
                                        router.asPath
                                    )}
                                    href={
                                        abTestPaging
                                            ? section.url
                                            : section.legacyUrl
                                    }
                                    id={`chapter-${index + 1}`}>
                                    {section.name}
                                </StyledAbLink>
                            ) : (
                                <StyledLink
                                    iscurrent={getIsCurrent(
                                        abTestPaging,
                                        section,
                                        router.asPath
                                    )}
                                    href={
                                        abTestPaging
                                            ? section.url
                                            : section.legacyUrl
                                    }
                                    id={`chapter-${index + 1}`}>
                                    {section.name}
                                </StyledLink>
                            )}
                        </StyledListItem>
                    ))}
                </StyledList>
            </StyledListWrapper>
            <StyledButtonWrapper>
                {abTestPaging ? (
                    <Link
                        href={getAllUrl(abTestPaging, page.url)}
                        id="show-text">
                        <Button
                            variant="outlined"
                            endIcon={<Icon name="expandMore" />}>
                            {t.article.showAllText}
                        </Button>
                    </Link>
                ) : (
                    <a
                        href={getAllUrl(abTestPaging, page.url)}
                        id="show-text">
                        <Button
                            variant="outlined"
                            endIcon={<Icon name="expandMore" />}>
                            {t.article.showAllText}
                        </Button>
                    </a>
                )}
            </StyledButtonWrapper>
        </StyledToC>
    )
}

function getIsCurrent(
    abTestPaging: boolean,
    section: { isCurrent: boolean; legacyUrl: string },
    asPath: string
) {
    if (abTestPaging) return section.isCurrent ? "true" : "false"

    if (section.legacyUrl == asPath) return "true"
    return "false"
}

function getAllUrl(abTestPaging: boolean, pageUrl: string) {
    if (abTestPaging) return `${pageUrl}${pageUrl.endsWith("/") ? "" : "/"}all`
    return `${pageUrl}${pageUrl.endsWith("/") ? "" : "/"}?page=all`
}

const StyledToC = styled("div")(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary[100]
}))

const StyledListWrapper = styled("div")(({ theme }) => ({
    paddingLeft: theme.spacing(2)
}))

const StyledList = styled("ol")(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    listStylePosition: "outside",
    fontSize: "1rem",
    paddingInlineStart: 0,
    columnCount: 1,
    [theme.breakpoints.up("md")]: {
        columnCount: 2
    }
}))

const StyledListItem = styled("li")(({ theme }) => ({
    padding: theme.spacing(1),
    maxWidth: 300,
    textOverflow: "ellipsis",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
}))

const StyledLink = styled("a")<{ iscurrent: string }>(
    ({ iscurrent }) => ({
        fontFamily: "inherit",
        ...(iscurrent === "true" && {
            fontWeight: 700
        }),
        "&:hover": {
            textDecoration: "none"
        },
        "-webkit-font-smoothing": "antialiased",
        "-webkit-text-size-adjust": "100%",
        listStylePosition: "outside",
        boxSizing: "inherit",
        margin: 0,
        fontSize: "1rem",
        lineHeight: 1.43,
        color: "#1F394C",
        textAlign: "right",
        textDecoration: "underline",
        textunderlineoffset: "0.1em",
        wordBreak: "break-word"
    })
)

const StyledAbLink = styled(Link)<{ iscurrent: string }>(({ iscurrent }) => ({
    fontFamily: "inherit",
    ...(iscurrent === "true" && {
        fontWeight: 700
    }),
    "&:hover": {
        textDecoration: "none"
    }
}))

const StyledButtonWrapper = styled("div")(() => ({
    display: "flex",
    justifyContent: "flex-end"
}))